import React, { Component } from 'react';
// import Identicon from 'identicon.js';
// import makeBlockie from 'ethereum-blockies-base64';


const Web3 = require('web3')
const web3 = new Web3('https://mainnet.infura.io/v3/8db5dad32a3f490dac7aaf6cb2b23b82')
web3.eth.accounts.create()


const newAccount = web3.eth.accounts.create()
console.log(newAccount)

class GutBc extends Component {

render() {
return (

<div class="container">



</div>


);
}
}

export default GutBc;
